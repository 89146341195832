import SectionTitle from '../components/SectionTitle';
import MeetNewWayConsulting from '../components/MeetNewWayConsulting';
import OurPersonality from '../components/OurPersonality';
import OurValues from '../components/OurValues';
import TechEdge from '../components/TechEdge';
import ClientCarrousel from "../components/ClientCarrousel";
import DigitalTraces from "../components/DigitalTraces";
import WeAreLookingForNimbers from '../components/WeAreLookingForNimbers';
import React from "react";
import '../styles/home.css'
import bgWave1 from '../components/backgroundWave1.svg';
import bgWave2 from '../components/backgroundWave2.svg';

function Home() {
    return (
        <div className={'centering'}>
            <div style={{ backgroundImage: `url(${bgWave1})`, backgroundRepeat: 'no-repeat', backgroundPosition: '0% 100%', width: '100%', display: "flex", justifyContent: "center", paddingTop:'100px'}}>
                <div className='container'>
                    <div>
                        <SectionTitle title={'Meet the new way of consulting'} imgSrc={'images/numbers/01.png'} />
                        <MeetNewWayConsulting />
                    </div>
                </div>
            </div>
            <div style={{ backgroundImage: `url(${bgWave2})`, backgroundRepeat: 'no-repeat', backgroundPosition: '0% 0%', width: '100%', display: "flex", justifyContent: "center" }}>
                <div className='container'>
                    <div>
                        <div className={'title-flex'}>
                            <SectionTitle title={'Our Personality'} imgSrc={'images/numbers/02.png'} />
                            <p className='section02a-text01'>The personality that makes sense for us to embrace.</p>
                        </div>
                        <div style={{ paddingBottom: '90px', paddingTop: '50px' }}>
                            <OurPersonality />
                        </div>
                        <div style={{ paddingBottom: '90px', paddingTop: '50px' }}>
                            <SectionTitle title={'Our Values'} imgSrc={'images/numbers/03.png'} />
                            <OurValues />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container' style={{ paddingBottom: '50px', paddingTop: '50px' }}>
                <div className='techedge-title'>
                    <SectionTitle title={'Techedge'} imgSrc={'images/numbers/04.png'} />
                </div>
                <TechEdge />
            </div>

            <div className={'clients-wrapper'}>
                <div className='container'>
                    {/* Clients Section */}
                    <div className="section-title nimber-margin-bottom-52" style={{ width: '80vw', overflowX:"hidden"}}>
                        <img src={'images/numbers/05.png'} alt={'5'}/>
                        <h3 className="section-title-text">Our Clients</h3>
                    </div>
                    <ClientCarrousel />
                </div>
            </div>

            <div className='container' style={{ marginBottom: '100px', marginTop: '100px'}}>
                <SectionTitle title={'Digital Traces'} imgSrc={'images/numbers/06.png'} />
                <DigitalTraces />
            </div>

            <div className={'footer-envelop'}>
                <div className='container'>
                    <WeAreLookingForNimbers />
                </div>
            </div>

        </div>
    );
}

export default Home;
