import SendUsYourCVForm from "./SendUsYourCVForm";
import '../styles/weAreLookingForNimbers.css';

const WeAreLookingForNimbers = () => {
    return (
        <div className="weAreLookingForNimbers-container">
            <div className="weAreLookingForNimbers-content-item">
                <h1>We are looking for Nimbers</h1>
            </div>
            <div className="weAreLookingForNimbers-content-row">
                <div className="weAreLookingForNimbers-content-item weAreLookingForNimbers-content-column">
                    <p>Because our employees and their talents are fundamental to our success,
                        we devote close attention to cultivating their technical knowledge and skills.</p>
                </div>
                <div className="weAreLookingForNimbers-content-item weAreLookingForNimbers-content-column">
                    <p>We understand the importance and strength of being a community and
                        encourage innovative collaborative projects and team bonding through a multitude of events.</p>
                </div>
                <div className="weAreLookingForNimbers-content-item weAreLookingForNimbers-content-column">
                    <p>Help us bring the power of creative tech to life,by supporting our customers in their business transformation.</p>
                </div>
                <div className="weAreLookingForNimbers-content-item weAreLookingForNimbers-content-column">
                    <a href={'mailto:hello@nimber.pt'} target={'_blank'}>
                        <img className={'rotate-img'} src="images/lets-talk.png" width={'150px'} height={'150px'} />
                    </a>
                </div>
            </div>
            {/*<div className="weAreLookingForNimbers-content-row weAreLookingForNimbers-content-right">*/}
            {/*    <img src="images/lets-talk.png" width={'150px'} height={'150px'} style={{ alignSelf: 'flex-end', position: 'relative', bottom: '-50px', right: '-50px' }} />*/}
            {/*    <SendUsYourCVForm />*/}
            {/*</div>*/}
        </div>
    );
};

export default WeAreLookingForNimbers;