// Header.js
import React from 'react';
import '../styles/header.css';

const Header = () => {
    const menuItems = ['Nimberverse', 'TechEdge', 'Nearshore', 'Opportunity Hub', 'Outer World'];

    return (
        <div className="header centering">
            <div className={'video-container'}>
                <video className="video" autoPlay loop muted>
                    <source src="/header2.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>

            <div className="header-menu-row centering" style={{justifyContent: "center"}}>
                <div className={'container'}>
                    <div className={'logo-holder'}>
                        <img className="logo" src="images/Logo-white.png" alt="Logo" />
                    </div>
                </div>
            </div>
            <div className={'container'} style={{ height: '100%'}}>
                <div className={"header-block-text"}>
                    <span className={'header-top-text'}>NIMBER</span>
                    <h3 className={'header-top-title'}>IS PLAYING THE GAME
                        OF ADVANCED
                        TECHNOLOGY
                        CONSULTANCY</h3>
                    <p className={'header-top-subtext'}>Delivering the right skills to any business — when and how they're needed.</p>
                </div>
            </div>
        </div>
    );
};

export default Header;
