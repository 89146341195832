import React from "react";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/home';
import Header from './routes/header';
import './styles/main.css';
import './styles/fonts.css';
import Nearshore from "./pages/nearshore";
import Footer from "./components/Footer";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />
    },
]);

function App() {
    return (
        <React.StrictMode>
            <Header />
            <RouterProvider router={router} />
            <Footer />
        </React.StrictMode>
    );
}

export default App;